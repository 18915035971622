@import "styles/variables";
@import "styles/mixins";

.carouselRow {
  margin-bottom: calc(#{$general-gutter} * 6);

  .productsWrapper {
    display: flex;
    flex-wrap: wrap;
    .productCard {
      position: relative;
      padding: 5px;

    }

    @media (min-width: $sm) {
      .productsWrapper {
        :global(.slick-slider) {
          padding: calc(2 *#{$general-gutter} - 10px)
            calc(2 *#{$general-gutter});
        }
      }
    }
  }

  .description {
    display: flex;
    padding: 5px;
    width: 100%;
    height: 100%;

    a {
      border-radius: 10px;
      display: flex;
      align-items: flex-end;
      color: $text-color;
      width: 100%;
      height: 100%;
      padding: 20px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 1px;
      font-weight: 300;
      color: #ffffff;
      transition: all ease-in-out 0.2s;

      svg {
        margin-left: 10px;
      }
      div {
        max-width: 100%;
      }
      .itemContent{
        max-width: 100%;
        overflow: auto;
      }

      @media (min-width: $sm) {
        font-size: 26px;
        line-height: 35px;
      }

      &:hover {
        text-decoration: none;
        transition: all ease-in-out 0.2s;
      }

      @include category-color();
    }
  }
}
