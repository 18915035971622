@mixin icon-by-kind-class(){
    &.item-PAMIATKY{
        .icon{
            background-color: #FBD136;
        }
    }
    &.item-SLUZBY{
        .icon{
            background-color: #3CA3DB;
        }
    }
    &.item-PRIRODA{
        .icon{
            background-color: #B3D33C;
        }
    }
    &.item-PREHLIADKY{
        .icon{
            background-color: #AC5787;
        }
    }
    &.item-PODUJATIA{
        .icon{
            background-color: #F15F23;
        }
    }
}

.wishlist{
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    cursor: pointer;
    z-index: 2;
    margin-right: 10px;
    .icon{
        position: absolute;
        left: 1px;
        top: 1px;
        width: 26px;
        height: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background-color 0.3s;
    }
    .text{
        display: flex;
        overflow: hidden;
        height: 28px;
        border-radius: 14px;
        font-size: 14px;
        color: black;
        font-weight: 300;
    }
    &.default{
        .text{
            border:1px solid transparent;
            align-items: center;
            padding-left: 38px;
            padding-right: 12px;
            transition: all 0.3s;
        }
        &:hover{
            @include icon-by-kind-class;
            .text{
                border: 1px solid black;
            }
        }
    }
    &.small{
        .icon{
            background-color: #eee;
        }
        .text{
            width: 0;
            background-color: white;
        }
        &:hover{
            @include icon-by-kind-class;
            .text{
                width: auto;
                padding-left: 38px;
                padding-right: 12px;
                border: 1px solid black;
            }
        }
    }
    &.active{
        @include icon-by-kind-class;
        .text{
            background-color: white;
        }
    }
}

