@import "styles/variables";

#navigation {
  z-index: 3;
  position: relative;
  margin-top: -40px;
  padding-top: 80px;
  .logoWrapper{
    display: flex;
    margin-right: auto;
    @media (max-width:$mobile-menu-breakpoint) {
      display: none;
    }
  }
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 20px;
    @media (min-width: 1100px) {
      flex-wrap: nowrap;
    }
    li {
      display: block;
      align-items: center;
      margin-bottom: 10px;
      a {
        display: flex;
        align-items: center;
        color: black;
        transition: all 0.2s;
        background-position: 17px center;
        background-repeat: no-repeat;
        width: auto;
        font-weight: bold;
        .photo {
          height: 64px;
          width: auto;
          aspect-ratio: 1;
          border-radius: 100%;
          img {
            opacity: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%
          }
          &.item-PAMIATKY {
            background-color: $color-pamiatky-bg;
          }
          &.item-SLUZBY {
            background-color: $color-sluzby-bg;
          }
          &.item-PRIRODA {
            background-color: $color-priroda-bg;
          }
          &.item-PREHLIADKY {
            background-color: $color-prehliadky-bg;
          }
          &.item-PODUJATIA {
            background-color: $color-podujatia-bg;
          }
        }
        &:hover {
          img {
            opacity: 1;
          }
        }
        picture {
          transition: all 0.2s;
        }
        span {
          display: flex;
          align-items: center;
          text-align: center;
          padding: 10px;
          font-weight: bolder;
          font-family: "Inter", "Manrope", sans-serif;
        }
        &.item-MICE {
          &:hover {
            color: black;
          }
        }

        &.categoryFallback {
          flex-direction: row !important;
          align-items: center;
          padding-left: calc(#{$general-gutter} / 2);
          padding-right: calc(#{$general-gutter} / 2);

          @media (min-width: 900px) {
            padding-left: $general-gutter;
            padding-right: $general-gutter;
          }

          img {
            background: transparent;
          }
          span {
            align-items: center;
          }
        }
      }

      &.inCategory {
        position: relative;
        min-height: 100px;

        @media (min-width: $md) {
          min-height: 130px;
        }

        a {
          position: absolute;
          left: 10px;
          top: 0;
          width: calc(100% - 20px);
          transition: all ease-in-out 0.2s;
          span {
            align-items: center;
          }
          img {
            // height: auto;
            height: 0;
            opacity: 0.5;
          }

          &:hover {
            img {
              height: auto;
              opacity: 1;
              transition: all ease-in-out 0.3s;
            }
          }
        }
      }
    }
    @media (max-width: $mobile-menu-breakpoint) {
      justify-content: space-between;
      gap:0;
      a{
        flex-direction: column;
        img {
          opacity: 1!important;
        }
        &:hover {
          img {
            opacity: 0!important;
          }
        }
      }
    }
  }
  .categoryCard {
    display: none;
    @media (min-width: $mobile-menu-breakpoint) {
      display: block;
    }

  }
  :global(.ant-carousel) {
    width: 100%;
    .categoryCard {
      display: block;
      font-size: 10px;
      //white-space: nowrap;
    }
    @media (min-width: $mobile-menu-breakpoint) {
      display: none;
    }
  }
}
