@import "variables";

@mixin category-color($useHover: true) {
  background-color: #fbd136;

  &.item-PAMIATKY {
    background-color: $color-pamiatky-bg;
    @if $useHover {
      &:hover {
        background-color: $color-pamiatky-bg-hover;
      }
    }
  }

  &.item-SLUZBY {
    background-color: $color-sluzby-bg;
    @if $useHover {
      &:hover {
        background-color: $color-sluzby-bg-hover;
      }
    }
  }

  &.item-PRIRODA {
    background-color: $color-priroda-bg;
    @if $useHover {
      &:hover {
        background-color: $color-priroda-bg-hover;
      }
    }
  }

  &.item-PREHLIADKY {
    background-color: $color-prehliadky-bg;
    @if $useHover {
      &:hover {
        background-color: $color-prehliadky-bg-hover;
      }
    }
  }

  &.item-PODUJATIA {
    background-color: $color-podujatia-bg;
    @if $useHover {
      &:hover {
        background-color: $color-podujatia-bg-hover;
      }
    }
  }

  &.item-MICE {
    background-color: $color-mice-bg;
    @if $useHover {
      &:hover {
        background-color: $color-mice-bg-hover;
      }
    }
  }
}
