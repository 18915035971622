@import "styles/variables";

.row {
  h2 {
    color: #ffffff;
    font-size: 26px;
    font-weight: 600;
    display: block;
    width: 100%;
  }

  .pagesWrapper {
    padding: 5px;
  }
}
