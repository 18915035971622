@import "styles/variables";
.wrapper {
  .marginBottom {
    margin-bottom: calc(#{$general-gutter} * 6);
  }

  .content {
    margin: 20px 0;
    @media (min-width: 700px) {
      margin: 40px 0;
    }
    @media (min-width: 1000px) {
      margin: 80px 0;
    }
  }
}
