.wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  button {
    font-family: var(--f_headings);
  }
  .loading{
    svg{
      margin: 0;
    }
  }
}
