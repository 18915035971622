@import "styles/variables";

$card-size: 0px;
$card-mobile-size: 0px;

a.card {
  &:not(.main):hover {
    text-decoration: none;
    background: $grey-bg-color-hover;
  }
}

.card {
  aspect-ratio: 1;
  display: flex;
  flex-wrap: wrap;
  background: $grey-bg-color;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  border-radius: 10px;

  @media (min-width: $md) {
    min-width: $card-size;
    min-height: $card-size;
    padding: 20px;
  }

  &.main {
    background: $color-sluzby-bg;

    @media (min-width: $md) {
      position: sticky;
      left: 0;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  .icon {
    margin-bottom: $general-gutter;
  }

  .title {
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0.3px;
    width: 100%;
    padding-right: 60px;

    @media (min-width: $md) {
      font-size: 26px;
      line-height: 35px;
    }
  }
}
