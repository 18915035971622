.productCard {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    a{
        display:block;
        width: 100%;
        height: 100%;
    }
    &:after{
        content: " ";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    }
    picture,img{
        display: block;
        max-width: 100%;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
    }
    h2{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 10px;
        color: white;
        font-size: 18px;
        line-height: 21px;
        font-weight: 300;
        z-index: 2;
    }
    .eventTime {
        font-size: 1rem;
        font-weight: 200;
        margin-top: 10px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 1.5s;
    }
    .wishlistWrapper{
        position: absolute;
        top: 10px;
        left: 10px;
    }
    &:hover{
        img{
            transform: scale(1.1);
        }
        .eventTime {
            max-height: 200px;
        }
    }
}
