@import "styles/variables";

.grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .gridItem{
        flex: 0 0 100%;
        @media (min-width: 700px) {
            flex: 0 0 calc(50% - 5px);
        }
    }
}
.datePicker {
    margin-bottom: 5px;
    display: flex;
    gap: 0.5rem;
    border: solid 1px $text-color;
    width: fit-content;
    padding: 10px 15px;
    cursor: pointer;
}
.datePickerWrapper {
    position: absolute;
    z-index: 100;
}
