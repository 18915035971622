.wrapper{
    .row{
        display: flex;
        justify-content: space-between;
        .col{
            display: flex;
            flex: 0 0 calc(50% - 5px);
            margin-bottom: 10px;
            .item{
                width:100%;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &.variant1{
        .row{
            &:last-child{
                .col{
                    flex: 0 0 100%;
                }
            }
        }
    }
    &.variant2{
        .row{
            .col{
                flex-wrap: wrap;
                &:last-child{
                    .item{
                        height: calc(50% - 5px);
                    }
                }
            }
        }
    }
    &.variant3{
        .row{
            .col{
                flex-wrap: wrap;
            }
        }
    }
    &.variant4{
        .row{
            &:first-child{
                .col{
                    flex: 0 0 100%;
                }
            }
        }
    }
}