@import "styles/variables";

.headBar {
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: $lg) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .logoWrapper {
    a {
      display: block;
      picture,
      img {
        width: 106px;
        display: inline-block;
      }
    }
  }
  h1 {
    position: relative;
    color: black;
    font-weight: normal;
    font-size: 1.8em;
    padding: 0 20px;
    margin: 0;
    z-index: 2;
    @media (min-width: 400px) {
      padding: 0 40px;
    }
    @media (min-width: 700px) {
      font-size: 2.2em;
      padding: 0;
    }
  }
  h2 {
    color: white;
    font-weight: 300;
    font-size: 1.5em;
    margin: 15px 0 0 0;
    position: relative;
    z-index: 2;
    padding: 0 20px;
  }
}
