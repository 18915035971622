.socialCard{
    display: block;
    position: relative;
    picture, img{
        display: block;
        width: 100%;
    }
    .content{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        svg{
            transition: all 0.3s;
            transform: scale(0.5);
            
        }
    }
    &:hover{
        .content{
            svg{
                transform: scale(0.6);
            }
        }
    }
    @media (min-width: 700px) {
        .content{
            svg{
                transform: scale(1.1);
            }
        }
        &:hover{
            .content{
                svg{
                    transform: scale(1.0);
                }
            }
        }
    }
}