@import "styles/variables";
.searchContainer{
  z-index: 6;
}
.search-wrapper {
  position: relative;
  max-width: 600px;
  margin: 20px auto 0;
  z-index: 2;
  :last-child {
    padding-right: 10px;
  }
  form {
    width: 100%;
    display: flex;
    border-radius: 25px;
    padding-right: 50px;
    position: relative;
    justify-content: space-between;
  }
  input{
      color: $text-color;
      padding: 17px;
    border: solid 2px $text-color;
    background: none;
      box-shadow: none;
      margin: 0;
      font-size: 1em;
    font-weight: bold;
    &::placeholder {
      color: $text-color;
    }
      &.searchInput{
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right: none;
        width: 100%;
        padding-left: 50px;
        @media (max-width: $md) {
          border-right: solid 2px $text-color;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }
  }
  .submitButton{
    background: none;
      border-radius: 50%;
    width: fit-content;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 14px;
      text-align: center;
      border:none;
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: center;
    svg {
      padding: 0;
      width: 23px;
      height: 23px;
    }
  }

  @media (min-width: 1000px){
      max-width: 800px;
  }

  .datePicker{
    position: relative;
    margin-right: 10px;
    border: solid 2px $text-color;
    border-left: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;


    @media (max-width: $md) {
      display: none;
    }

    .icon{
      height: 20px;
      width: 20px;
      font-size: 20px;
      display: flex;
      align-items: center;
      padding: 0;
      margin-right: 10px;
      svg {
        padding: 0;
      }
    }
    .text{
      display: none;
      @media (min-width: $sm) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        & > div{
          position: relative;
          display: flex;
          justify-content: flex-start;
          padding-left: 10px;
          margin-left: 10px;
          white-space: nowrap;
          font-size: 0.9em;
          color: $text-color;
          text-align: left;
          strong{
            color: black;
          }
        }
        .separator{
          width: 0;
          height: 38px;
          border-right: 1px solid $text-color;
        }
      }
    }

  }

  .datePickerWrapper{
    position: absolute;
    top: 60px;
    right: 0;
  }

  :global{
    .react-datepicker{
      border: none;
      border-end-start-radius: $general-radius;
      box-shadow: $general-shadow;
      .react-datepicker__navigation{
        .react-datepicker__navigation-icon{
          top: 0;
          font-size: 1em;
        }
      }
      .react-datepicker__month-container{
        float: none;
        .react-datepicker__header{
          background-color: transparent;
          border-bottom: none;
          .react-datepicker__day-names{
            display: none;
          }
        }
        .react-datepicker__month{
          .react-datepicker__week{
            .react-datepicker__day{
              border-radius: $general-radius;
              &.react-datepicker__day--keyboard-selected{
                background-color: white;
                color: black;
              }
              &.react-datepicker__day--today{
                background-color: $color-pamiatky-bg;
                color: black;
              }
              &.react-datepicker__day--in-range{
                background-color: black;
                color: white;
                opacity: 0.8;
              }
              &.react-datepicker__day--in-selecting-range{
                background-color: black;
                color: white;
                opacity: 0.5;
              }

            }
          }
        }
      }
    }
  }

}

.results {
  position: absolute;
  left: 0;
  top: 140px;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  margin-top: -40px;
  padding: 0 15px;

  .resultsWrapper {
    position: relative;
    display: flex;
    width: 100%;
    background: white;
    flex-direction: column;
    box-shadow: 0px 10px 24px 2px rgba(53, 48, 48, 0.3);
    overflow: auto;
    border-radius: $general-radius;

    @media (min-width: 500px) {
      max-width: 600px;
    }

    @media (min-width: 600px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (min-width: 1000px) {
      width: 800px;
      max-width: none;
      max-height: 500px;
      flex-direction: row;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      color: $grey-color;
      display: block;
      font-size: 20px;
      cursor: pointer;
    }

    .noResults {
      width: 100%;
      text-align: center;
      padding: 20px 10px;
    }

    h2.heading {
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 15px;
      color: $text-color;
      white-space: nowrap;
      padding: 0;
    }

    .resultsLeft {
      width: 100%;
      background: rgba($grey-color, 0.3);
      padding: 10px 20px;
      text-align: left;

      @media (min-width: 600px) {
        width: 50%;
        max-height: 305px;
        overflow: auto;
      }

      @media (min-width: 1000px) {
        width: 30%;
        max-height: none;
        overflow: auto;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;

        &.inline {
          width: 100%;

          li {
            display: inline-flex;
            margin: 5px 5px 0 0;
          }
        }

        li {
          list-style: none;

          a {
            padding: 10px 0;
            color: black;
            display: block;

            &:hover {
              text-decoration: underline;
            }

            &.tag {
              border-radius: 15px;
              background: white;
              padding: 5px 10px;
              font-size: 13px;

              &:hover {
                background: $primary-color;
                color: white;
              }
            }
          }
        }
      }
    }

    .resultsRight {
      width: 100%;
      background: white;
      padding: 10px 20px;

      @media (min-width: 600px) {
        width: 50%;
        max-height: 305px;
        overflow: auto;
      }

      @media (min-width: $md) {
        width: 35%;
        max-height: none;
      }
    }
  }
}

.searchIcon {
  span {
    padding: 0 !important;
    margin: 0 !important;
  }
}
