.tag-icons {
  &__icon {
    display: inline-block;
    margin-left: 10px;
  }

  &__img {
    width: auto;
    height: auto;
    img {
      height: 12px;
    }
  }
}