$footer-color: rgb(57, 12, 99);

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-gutter-2: 30px;
$general-gutter-3: 45px;
$general-shadow: rgba(0, 0, 0, 0.15) 0px 0px 40px;
$general-radius: 2px;

$discount-color: rgb(230, 59, 69);
$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: rgb(80, 35, 122);
$primary-color-hover: rgb(70, 25, 112);

$secondary-color: rgb(175, 202, 5);

$text-color: rgb(17, 17, 17);
$text-white-color: #fff;
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$main-bg-color: rgb(255, 255, 255);
$grey-bg-color: #f4f4f4;
$grey-bg-color-hover: darken(#f4f4f4, 5%);
$black-bg-color: #000;

// MENU ITEMS COLORS
$color-pamiatky-bg: #fbd136;
$color-pamiatky-bg-hover: darken($color-pamiatky-bg, 5%);

$color-sluzby-bg: #3ca3db;
$color-sluzby-bg-hover: darken($color-sluzby-bg, 5%);

$color-priroda-bg: #b3d33c;
$color-priroda-bg-hover: darken($color-priroda-bg, 5%);

$color-prehliadky-bg: #ac5787;
$color-prehliadky-bg-hover: darken($color-prehliadky-bg, 5%);

$color-podujatia-bg: #f15f23;
$color-podujatia-bg-hover: darken($color-podujatia-bg, 5%);

$color-mice-bg: #F376A8;
$color-mice-bg-hover: darken($color-mice-bg, 5%);

$mobile-menu-breakpoint: 900px;


$search-button-bg: #3CA3DB;

//Srandon
$color-yellow: #F4C02E;
$color-blue: #412EFF;
$color-red: #FF6B64;
$color-purple: #9747FF;
$color-white: #FFF;
$color-gray: #707070;
$color-gray1: #F1F1F1;
